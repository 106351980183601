// Mock testimonial data
const testimonials = [
  {
    id: 1,
    name: "Andrew Senn",
    role: "National Manager - Future Energy",
    company: "Northpower Ltd., New Zealand",
    image: "/images/north_power_logo.jpeg?height=30&width=30",
    quote: "The team at SURYA delivered value at every stage of the project, from highlighting bill of material errors at the earliest stage through to flexibility in configuration to allow a variety of reporting the functionality and flexibility of SURYA is impressive, this is a tool we would carry into any project."
  },
  {
    id: 3,
    name: "Tanya Zdebliak",
    role: "Construction supervisor ",
    company: "Northpower Ltd., New Zealand",
    image: "/images/north_power_logo.jpeg?height=30&width=30",
    quote: "Working with the SURYA App out in the field was so easy to use and made the project run exceptionally smooth. Easy to pass on information to the Contractors for mechanical build, Quality Control team for QC checks and reporting up to the Executive team. A true asset for this exciting project."
  },
  {
    id: 2,
    name: "Dan Thornett",
    role: "Construction Supervisor",
    company: "Bam Bam Pile Driving",
    image: "/images/bam_bam_logo.jpeg?height=30&width=30",
    quote: "SURYA is a game changer when building utility scale solar farms. It makes everything from logistics tracking and planning through to Quality and overall construction tracking so much easier. The team at SURYA are extremely accommodating with the project needs and are quick to help with providing app training and evolving the app to suit the needs for building the site."
  }
]

export default function TestimonialScroll() {

  return (
    <div className="container">
      <div>
        <div className="about__header">
          <h2>Client Testimonials</h2>
        </div>
        <div className="about__paragraph ml-20">
          <p style={{padding:"0%", fontweiht:"10"}}>
            Hear what our clients have to say about working with us
          </p>
        </div>
      </div>

      {/* Testimonials Container */}
      <div
        className="overflow-hidden"
        role="region"
        aria-label="Testimonials carousel"
        style={{minHeight:"800px",paddingBottom:"2%"}}
      >
        <div 
          className="flex transition-transform"
          style={{
            display:"flex",
            "flex-wrap": "wrap",
            "list-style": "none",
          }}
        >
          {testimonials.map((testimonial) => (
            <div
              key={testimonial.id}
              className="min-w-full px-4"
              style={{width:"33%",minWidth:"300px",marginTop:"6%"}}
            >
              <div className="bg-white rounded-xl shadow-lg p-6" style={{height:"100%",minHeight:"100px",minWidth:"50px",padding:"3%",display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between"}}>
                {/* Quote Icon */}
                <svg
                style={{width:"10%",height:"10%",marginBottom:"5%"}}
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z" />
                </svg>
                
                {/* Quote Text */}
                <p className="testimonial" style={{}}>{testimonial.quote}</p>
                
                <div>
                {/* Author Info */}
                <div className="flex items-center gap-4" style={{height:"15%", padding:"2%",margintop:"10%"}}>
                  <div className="row my-2">
                    <img
                  className="col"
                    src={testimonial.image}
                    alt={testimonial.name}
                    style={{width: "15px", borderRadius: "30%",marginBottom:"1%"}}
                  />
                    <p className="col-8 flex  align-content-center">{testimonial.name}</p>
                    </div>
                  
                    <p className="text-sm text-gray-600" style={{textAlign:"center"}}>{testimonial.role}</p>
                    <p className="text-sm font-medium text-blue-600" style={{textAlign:"center"}}>
                      {testimonial.company}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}